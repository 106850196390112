.loadingPosmarket .loading{
background-color: #000;
opacity: 0.5;
z-index: 9001!important;
height: 100%!important;
width: 100%!important;
position: fixed!important;
top:0;
left: 0;
}

.loadingPosmarket .loadingContent{
z-index: 9002!important;
position: absolute!important;
left: 50%;
top:50%;
transform: translate(-50%,-50%);
float: left;
color:white;
font-size: 25px;
}

.loadingPosmarket .loadingContent .text{
margin-right: 15px;
}

.loadingPosmarket .loadingContent .text,.spinner{
float: left;
}

.loadingContent .p-progress-spinner-circle{
    stroke: blue;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
}