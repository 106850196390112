.threeDs {
    width: 400px;
    height: 600px;
    margin: 100px 0 0 -175px;
    position: absolute;
    top: 0;
    left: 40%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
    background-color: white;
}

#payment-wallet iframe { width: 100%}


