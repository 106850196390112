.orderlist-demo .product-item {
    display: flex;
    border-left: 6px solid #00bcd4 ;
    margin-left: -10px;
    margin-right: -10px;
    align-items: center;
    padding: .5rem;
    overflow: hidden;
    min-height: 80px;
    text-overflow: ellipsis !important;
    border: 0.5px solid #c8c8c8;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;

}

.orderlist-demo .product-item2 {
    border-left: 6px solid #00bcd4 ;
    margin-left: -10px;
    padding: .5rem;
    width: 100%;
    overflow: hidden;
    min-height: 18rem;
    text-overflow: ellipsis !important;
    border: 0.5px solid #c8c8c8;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    align-items: center;
    align-content: space-between;
}

.orderlist-demo .product-item img {
    max-width: 55px;
    max-height: 55px;
    min-height: 55px;
    min-width: 55px;
    object-fit: scale-down;
    margin-right: 1rem;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
}

.orderlist-demo .product-item2 img {
    object-fit: scale-down;
    -webkit-border-radius: 10px !important;
    margin: auto;
    display: flex;
    height: 80%;

    width: 80%;
    border-radius: 10px !important;
}

.orderlist-demo .product-tag {
    display: flex;
    width: 5%;
}

.orderlist-demo .product-tag img {
    font-size: .7rem;
    height: 1.0rem;
    width: 1.0rem;
    max-height:  1.0rem;
    max-width: 1.0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;

}
.orderlist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.orderlist-demo .product-item2 .product-list-detail {
    flex: 1 1 0;
}
.orderlist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderlist-demo .product-item2 .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderlist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.orderlist-demo .product-item2 .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.orderlist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}
.orderlist-demo .product-item2 .product-category {
    vertical-align: middle;
    line-height: 1;
}

@media screen and (max-width: 576px) {
    .orderlist-demo .product-item {
        flex-wrap: wrap;
    }

    .orderlist-demo .product-item2 {
        flex-wrap: wrap;
        min-width: 10rem;
        min-height: 20rem;
        margin-right: 10px;
    }

    .orderlist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }
    .orderlist-demo .product-item2 .image-container {
        width: 100%;
        text-align: center;
    }

    .orderlist-demo .product-item img {
        margin: 0 0 1rem 0;
        max-width: 160px;
        max-height: 80px;
    }

    .orderlist-demo .product-item2 img {
        max-width: 7rem;
        max-height: 7rem;
        min-width: 7rem;
        min-height: 7rem;
        margin-top:1rem;
        margin-bottom: 1rem;
        margin-left:3rem;
        margin-right: 3rem;
        margin: 2rem 0 1rem 0;
        min-width: 160px;
        max-height: 120px;
    }
}