@media screen and (min-width: 768px) {
    body .p-component {
        font-family: JetSansDigital,Arial,sans-serif !important;
        line-height: 1.2 !important;
        font-size: 12px !important;
    }
    .footerMemberMobile {
        display: none;
    }

    .p-inputtext{
        font-size: 12px !important;
    }

    .col-form-label-sm {
        line-height: 1.2 !important;
        font-size: 12px !important;
    }

    .p-multiselect{
        width: 100% !important;
        font-size: 12px !important;
    }

    .form-control{
        height: calc(1.65rem) !important;
        font-size: 12px !important;
        padding: .0rem .75rem !important;
    }

    .layout-wrapper .layout-sidebar ul li a {
        font-size: 12px !important;
    }

    .btn{
        line-height: 1 !important;
        font-size: 13px !important;
    }

    .p-panel-titlebar{
        background-color: #1982D9 !important;
        border: 1px solid #1982D9;
        /*background-color: #78909c !important;*/
        /*border: 1px solid #78909c;*/
        color: #ffffff !important;
        font-size: 13px !important;
        font-weight: bold !important;
    }

    .p-fieldset-legend{
        font-size: 13px !important;
        font-weight: bold !important;
        margin: 0 !important;
        width: auto !important;
        color: #1982D9;
        padding: .2em 1em !important;
    }
}

@media screen and (max-width: 767px) {
    body .p-component {
        font-family: JetSansDigital,Arial,sans-serif !important;
        line-height: 1 !important;
        font-size: 12px !important;
    }

    .footerMemberMobile {
        z-index: 999999;
        border-top:1px white;
        position: fixed;
        width: 100%;
        bottom: 5px;
        color: white;
    }
    .p-inputtext{
        font-size: 12px !important;
    }

    .col-form-label-sm {
        line-height: 1 !important;
        font-size: 12px !important;
    }

    .p-multiselect{
        width: 100% !important;
        font-size: 12px !important;
    }

    .form-control{
        height: calc(1.65rem) !important;
        font-size: 12px !important;
        padding: .0rem .75rem !important;
    }

    .layout-wrapper .layout-sidebar ul li a {
        font-size: 12px !important;
    }

    .btn{
        line-height: 1 !important;
        font-size: 18px !important;
    }

    .p-panel-titlebar{
        background-color: #1982D9 !important;
        border: 1px solid #1982D9;
        /*background-color: #78909c !important;*/
        /*border: 1px solid #78909c;*/
        color: #ffffff !important;
        font-size: 11px !important;
        font-weight: bold !important;
    }

    .p-fieldset-legend{
        font-size: 12px !important;
        font-weight: bold !important;
        margin: 0 !important;
        width: auto !important;
        color: #1982D9;
        padding: .2em 1em !important;
    }
}

.MuiPaper-rounded {
    border-radius: 15px !important;
}

.MuiInputLabel-outlined {
    background-color: #fff !important;
}

/*body .p-component {*/
/*    line-height: 1.2 !important;*/
/*    font-size: calc(0.2em + 1vmin) !important;*/
/*}*/

.upload-img {
    border: 3px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    max-width: 60%;
    height: auto;
}

.upload-img:hover {
    box-shadow: 0 0 3px 2px rgba(0, 140, 186, 0.5);
}

.datatable-img {
    max-height: 25px !important;
    max-width: 25px !important;
    height: auto;
}

.datatable-img:hover {
    box-shadow: 0 0 3px 2px rgba(0, 140, 186, 0.5);
}

.p-grid {
    margin-right: 0 !important;
    margin-top: 0 !important;
}

.p-toolbar{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.p-green{
    color: green !important;
}

.p-red{
    color: green !important;
}

.layout-wrapper .layout-main {
    background-color: #ffffff !important;
    padding: 66px 8px 16px 8px !important;
    margin-right: 5%;
}

.layout-wrapper .layout-main-displaymember {
    background-color: #ffffff !important;
    padding: 10px 8px 16px 8px !important;
    margin-right: 1%;
}


.layout-wrapper .layout-topbar{
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 4px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, #FFFFFF 0%, #f8f9fa 100%) !important;
}


.layout-topbar a {
    color: #000000 !important;
}

.p-card {
    border-left: 6px solid #00bcd4 ;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight {
    background-color: #39a3f4 !important;
    color: #ffffff !important;
}

.p-panel, .p-fieldset{
    background-color: #ffffff;
    color: #333333;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 2px 1px -1px rgba(0, 0, 0, 0);
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
}

.login-background{
    position: absolute;
    width: 100%;
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.p-dialog-mask{*/
/*    z-index: 999 !important;*/
/*}*/


.p-datatable-tbody * {
    /*border: 0px !important;*/
    /*border-top: 1px #e5e5e5 solid !important;*/
    text-overflow: ellipsis !important;
    overflow-x: hidden !important;
    /* white-space: nowrap; */
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.9em 0.5em !important;
    /*border: 1px solid #eceff1 !important;*/
}

.p-datatable-resizable .p-datatable-thead>tr>th {
    overflow: unset !important;
}

.p-dropdown {
    min-width: auto !important;
}

/*.p-editable-column *{*/
/*overflow: auto !important;*/
/*}*/

/*.p-datatable-thead * {*/
/*!*border: 0px !important;*!*/
/*!*border-top: 1px #e5e5e5 solid !important;*!*/
/*text-overflow: ellipsis !important;*/
/*overflow: hidden !important;*/
/*}*/

/*.p-datatable-row {*/
/*    border-right: 1px solid #e9ecef !important;*/
/*    border-left: 1px solid #e9ecef !important;*/
/*}*/

.selectTableButton{
    position: absolute;
    right: -10px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTableButtonForBlock{
    position: absolute;
    right: 6px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTableButton2{
    position: absolute;
    right: 25px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTablePlusButton25{
    position: absolute;
    right: 25px;
    top: 0;
    margin: 0;
    height: 100%;
}

.selectTablePlusButton60{
    position: absolute;
    right: 60px;
    top: 0;
    margin: 0;
    height: 100%;
}


.btn-primary, .btn-secondary, .btn-success, .btn-danger{
    margin-right: 10px !important;
    max-height: 29px;
}

/* DateInput */
.react-datepicker-wrapper, .react-datepicker__input-container{
    width: 100%;
}


.p-fieldset{
    margin-bottom: 0.6rem;
}

label {
    font-weight: bold !important;
}

.p-dropdown-label{
    margin-bottom: 0 !important;
}


.p-multiselect-label {
    font-weight: 400 !important;
}

.form-group .p-disabled {
    background-color: #e9ecef !important;
    opacity: 1 !important;
}

.my-multiselected-item-token {
    padding: .0rem .75rem !important;
    display: inline-block;
    vertical-align: middle;
    height: 1.857em;
    border-radius: 3px;
}

.p-steps .p-steps-item {
    width: auto !important;
}

.p-steps .p-steps-item .p-menuitem-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.form-group {
    margin-bottom: 0.5rem !important;
}

label {
    margin-bottom: .25rem !important;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.textAreaPosmarketSmall {
    height: 50px !important;
}
.textAreaPosmarket {
    height: 100px !important;
}

.textAreaPosmarketBig {
    min-height: 175px !important;
}

.textAreaPosmarketSoBig {
    min-height: 280px !important;
}

.textAreaCloseDialog {
    height: 200px !important;
}

.captchaButton {
    width:100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.dashboard .overview-box {
    text-align: center;
    color: #ffffff;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.dashboard .overview-box .overview-box-header {
    height: 24px;
}
.dashboard .overview-box .overview-box-content {
    padding: 8px 14px 14px 14px;
}
.dashboard .overview-box .overview-box-content .overview-box-icon {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    margin-top: -28px;
}
.dashboard .overview-box .overview-box-content .overview-box-icon i {
    line-height: inherit;
    font-size: 28px;
}
.dashboard .overview-box .overview-box-content .overview-box-title {
    font-size: 14px;
}
.dashboard .overview-box .overview-box-content .overview-box-count {
    font-size: 24px;
}
.dashboard .overview-box.overview-box-1 .overview-box-header {
    background-color: #f06292;
}
.dashboard .overview-box.overview-box-1 .overview-box-content {
    background-color: #e91e63;
}
.dashboard .overview-box.overview-box-1 .overview-box-content .overview-box-icon {
    background-color: #e91e63;
}
.dashboard .overview-box.overview-box-2 .overview-box-header {
    background-color: #4dd0e1;
}
.dashboard .overview-box.overview-box-2 .overview-box-content {
    background-color: #00bcd4;
}
.dashboard .overview-box.overview-box-2 .overview-box-content .overview-box-icon {
    background-color: #00bcd4;
}
.dashboard .overview-box.overview-box-3 .overview-box-header {
    background-color: #ffd54f;
}
.dashboard .overview-box.overview-box-3 .overview-box-content {
    background-color: #ffc107;
}
.dashboard .overview-box.overview-box-3 .overview-box-content .overview-box-icon {
    background-color: #ffc107;
}
.dashboard .overview-box.overview-box-4 .overview-box-header {
    background-color: #9e9e9e;
}
.dashboard .overview-box.overview-box-4 .overview-box-content {
    background-color: #616161;
}
.dashboard .overview-box.overview-box-4 .overview-box-content .overview-box-icon {
    background-color: #616161;
}

.dashboard .timeline {
    box-sizing: border-box;
    margin-top: 0;
}
.dashboard .timeline > .p-grid .p-col-3 {
    font-size: 14px;
    position: relative;
    border-right: 1px solid #dddddd;
}
.dashboard .timeline > .p-grid .p-col-3 i {
    font-size: 24px;
    position: absolute;
    top: 6px;
    right: -12px;
}
.footerMerchant {
    background-color: white;
    border-top:2px white;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0px;
    font-size: 20px;
}



.footerMemberCartButton {
    width: 95% !important;
    background-color: #1982D9 !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;
}
.footerMember {
    background-color: white;
    border-top:3px white;
    width: 100%;
    bottom: -10px;
    color: white;
    position: inherit;


}

.separator {
    text-align: center;
    height: 0.5em;
    border-bottom: 2px solid black;
    margin-bottom: 0.5em;
}
.separator:first-line {
    background-color: white;
}
.footer {
    background-color: white;
    border-top:2px white;
    position: absolute;
    width: 98%;
    text-align: center;
    bottom: 0px;
    font-size: 20px;
}

.p-password input {
    width: 15rem;
}
.dashboard .timeline > .p-grid .p-col-9 {
    padding-left: 1.5em;
}
.dashboard .timeline > .p-grid .p-col-9 .event-text {
    color: #7a7a7a;
    font-size: 14px;
    display: block;
    padding-bottom: 20px;
}
.dashboard .timeline > .p-grid .p-col-9 .event-content img {
    width: 100%;
}

.highlightTableRow{
    background-color: #fb6161f5  !important;
}

.highlightTableRowNoBorder{
    border: 0px solid !important;
}


.highlightTableRowRed{
    background-color: #fb6161f5;
    animation: blink-animation-red 1s 1s infinite ;
}
.highlightTableRowYellow{
    animation: blink-animation-yellow 1s 1s infinite;
}
.highlightTableRowGreen{
    animation: blink-animation-green 1s 1s infinite;
}

@keyframes blink-animation-red {
    50% {
        background-color: #fb6161f5;
    }
}
@keyframes blink-animation-green {
    50% {
        background-color: lightgreen;
    }
}
@keyframes blink-animation-yellow {
    50% {
        background-color: lightyellow;
    }
}


.highlightTableRowPending{
    background-color: rgb(175, 255, 112) !important;
}

.highlightTableRowRefund{
    background-color: rgb(184,199,214) !important;
}

.warningTableRow{
    background-color: #F9A825 !important;
}

.selectControlButton{
    position: absolute;
    right: 27px;
    top: 0;
    margin: 0;
    height: 100%;
}

.displayNone{
    display: none !important;
}

.footerToolBar label{
    width: 89px;
    float: right;
    /* border-right: 1px solid; */
    padding: 0 21px;
}

.pivot-datatables-class .p-datatable-wrapper{
    width: 100% !important;
    overflow: auto !important;
    overflow-y: scroll;
    border-right: 1px solid #c8c8c8;
}

.pivot-datatables-class .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: initial;
}

.card-chart .connection-chart-legend .p-grid{
    margin-right: -0.2em !important;
    margin-left: -0.2em !important;
    margin-top: -0.2em !important;
}
